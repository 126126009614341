let websock = null
let messageCallback = null
let resCallback = null
let errorCallback = null
let wsUrl = ''
let tryTime = 0
let interval = null
let data = null
let state = false
 
// 接收ws后端返回的数据
function websocketonmessage(e) {
	if (e.data instanceof Blob && e.data.size === 0) {
		//心跳
		messageCallback(e.data)
	} else {
		//返回数据
		messageCallback(JSON.parse(e.data))
	}
}
 
/**
 * 发起websocket连接
 * @param {Object} agentData 需要向后台传递的参数数据
 */
function websocketSend(agentData) {
	// 加延迟是为了尽量让ws连接状态变为OPEN
	setTimeout(() => {
		// 添加状态判断，当为OPEN时，发送消息
		if (websock.readyState === websock.OPEN) {
			// websock.OPEN = 1
			// 发给后端的数据需要字符串化
			if (agentData == 'ping') {
                //发送心跳
				const pingMsg = new Uint8Array()
				websock.send(pingMsg)
			} else {
                //发送消息
				websock.send(JSON.stringify(agentData))
			}
		}
		if (websock.readyState === websock.CLOSED) {
			// websock.CLOSED = 3
			console.log('websock', 'ws连接断开')
			clearInterval(interval)
			errorCallback()
		}
	}, 500)
}
 
//向后端发送消息
export function websocketSendMess(agentData) {
	websock.send(JSON.stringify(agentData))
}
 
// 关闭ws连接
function websocketclose(e) {
	// e.code === 1000  表示正常关闭。 无论为何目的而创建, 该链接都已成功完成任务。
	// e.code !== 1000  表示非正常关闭。
    //可以根据code情况判断 是否要重连 
	if (e) {
		console.log('ws连接异常，请稍候重试')
		clearInterval(interval)
		errorCallback()
		// 如果需要设置异常重连则可替换为下面的代码，自行进行测试
        //重新连接几次后 是否继续重新 自行判断tryTime
		setTimeout(function () {
			websock = null
			tryTime++
			sendWebsocket(wsUrl, data, messageCallback, resCallback, errorCallback)
			console.log(`第${tryTime}次重连`)
		}, 3 * 1000)
	}
}
// 建立ws连接
function websocketOpen(e) {
	tryTime = 0
	resCallback(e)
}
 
// 初始化weosocket
function initWebSocket() {
	if (typeof WebSocket === 'undefined') {
		console.log('您的浏览器不支持WebSocket，无法获取数据')
		return false
	}
 
	// ws请求完整地址
	websock = new WebSocket(wsUrl)
 
	websock.onmessage = function (e) {
		websocketonmessage(e)
	}
	websock.onopen = function () {
		websocketOpen()
	}
	websock.onerror = function () {
		console.log('ws连接异常，请稍候重试')
		closeWebsocket()
		errorCallback()
	}
	websock.onclose = function (e) {
		websocketclose(e)
	}
}
 
/**
 * 发起websocket请求函数
 * @param {string} url ws连接地址
 * @param {Object} agentData 传给后台的参数
 * @param {function} successCallback 接收到ws数据，对数据进行处理的回调函数
 * @param {function} errCallback ws连接错误的回调函数
 * @param {function} resorCallback ws连接成功的回调函数
 */
export function sendWebsocket(url, agentData, successCallback, errCallback, resorCallback) {
	wsUrl = url
	data = agentData
	initWebSocket()
	messageCallback = successCallback
	resCallback = resorCallback
	errorCallback = errCallback
	websocketSend(agentData)
	//保持心跳
	clearInterval(interval)
	interval = setInterval(() => {
		websocketSend('ping')
	}, 1000 * 5)
}
 
/**
 * 关闭websocket函数
 */
export function closeWebsocket() {
	if (websock) {
		clearInterval(interval)
		websock.close() // 关闭websocket
		websock.onclose() // 关闭websocket
	}
}